<template>
  <form class="flex flex-col items-center w-full"
        @submit="handleSubmit"
  >
    <div class="relative z-20 grid w-full mt-6 md:grid-cols-2 class gap-y-2 gap-x-4">
      <label class="text-sm md:order-1"> {{ areaLabel }}</label>

      <Dropdown :items="AREAS"
                :model-value="refine.area"
                :placeholder="defaultArea.label"
                class="md:order-3"
                @change="(item:any) => setSelected(item, JobFilterId.AREA)"
      />

      <label class="mt-2 text-sm lg:mt-0 md:order-2"> {{ locationLabel }}</label>

      <Dropdown :items="LOCATIONS"
                :model-value="refine.location"
                :placeholder="defaultLocation.label"
                class="md:order-4"
                @change="(item:any) => setSelected(item, JobFilterId.LOCATION)"
      />
    </div>

    <label class="mt-4 mr-auto text-sm"> {{ inputLabel }}</label>
    <SectionNewsletterFormInput v-model="inputEmail"
                                :state="state"
                                :label="inputLabel"
                                :placeholder="inputPlaceholder"
                                class="w-full mt-2 md:w-full sm:w-full"
                                type="small"
    >
      <SectionNewsletterFormButton :state="state"
                                   :label="submitLabel"
                                   type="small"
      />
    </SectionNewsletterFormInput>

    <SectionNewsletterFormCheckbox :state="state"
                                   :copy="consentLabel"
                                   class="mt-6"
    />

    <SectionNewsletterFormToast :state="state"
                                :success-message="successMessage"
                                :error-message="errorMessage"
    />
  </form>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import useNewsletter from '../../../composables/use-newsletter'
import { AREAS, JobFilterId, LOCATIONS } from '~/constants/jobs'

interface FormToastProps {
  areaLabel: string
  locationLabel: string
  inputPlaceholder: string
  inputLabel: string
  submitLabel: string
  consentLabel: string
  successMessage: string
  errorMessage: string
}

defineProps<FormToastProps>()

const defaultArea = AREAS[0]
const defaultLocation = LOCATIONS[0]

const refine = ref({
  area: defaultArea,
  location: defaultLocation
})

const setSelected = (item: any, id: JobFilterId) => {
  refine.value[id as keyof typeof refine.value] = item
}

const { subscribe, state } = useNewsletter()

const inputEmail = ref('')

function handleSubmit(e: Event) {
  e.preventDefault()

  subscribe(inputEmail.value, 164, refine.value.location.label, refine.value.area.label).then(() => {
    inputEmail.value = ''
  }).catch(() => {
    console.log('Subscribe failed')
  })
}
</script>
